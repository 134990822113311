@import './vars.scss';

.menu {
	position: absolute;
	top: 50%;
	left: 50%;
	widtH: 100%;
	text-align: center;
	z-index: 10;

	transform: translate(-50%, -50%);

	&--item {
		display: inline-block;
		padding: 0 10px;
		position: relative;

		&:last-child {
			.menu--link {
				&:after {
					display: none;
				}
			}
		}
	}

	&--link {
		display: block;
		font-family: $f_text;
		color: $c_grey_dark;
		font-size: 2vw;
		padding: 30px 10px;
		text-transform: uppercase;
		transition: .4s;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 2px;
			height: 100%;
			background: $c_grey_dark;

		}

		&:hover {
			color: $c_grey;
			background: $c_grey_light;
			padding: 10px;
		}
	}
}

.button__cv {
	position: fixed;
	bottom: 0;
	left: 0;
	text-align: center;
	width: 100vw;
	height: 40px;
	background: $c_grey_dark;
	z-index: 10;

	&--link {
		font-family: $f_role;
		font-size: 20px;
		line-height: 40px;
		font-weight: bold;
		display: block;
		transition: .4s;
		color: $c_grey_light;

		&:hover {
			background: $c_green;
			color: $c_grey_light;
			// background: $c_grey_dark;
		}
	}
}
