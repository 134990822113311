@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans+SC|Unica+One|Manrope&display=swap');

$f_title: 'Unica One';
$f_role: 'Alegreya Sans SC';
$f_text: 'Manrope', sans-serif;

$c_grey: #ccc;
$c_grey_dark: #888;
$c_grey_light:#bbb;

$c_green: #709280;
$c_yellow: #f7df6a;
$c_blue: #7087ab;
$c_red: #e69696;
$c_teal: #99bfbf;
