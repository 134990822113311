@import './vars.scss';

* {
  font-family: $f_text;
  color: $c_grey_dark;
  font-size: 14px;
}

a {
	text-decoration: none;
	padding: 0;
	margin: 0;
}

button {
  text-decoration: none;
  color: $c_green;
  padding: 5px 0;
  display: block;
  transition: .4s;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
}

button:hover {
  color: $c_grey;
  background: $c_green;
  padding: 5px 10px;
}

body {
  background: $c_grey;
  padding: 20px 50px 50px 20px;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.inline_title {
  font-size: 11px;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 5px 0 0;
  padding: 0;
  line-height: 15px;
  font-weight: normal;
  color: $c_green;
}

.logo {
	position: absolute;
	top: 50px;
	right: 50px;
	text-align: center;
}

.title {
  font-family: $f_title;
  text-align: right;
  font-size: 32px;
  line-height: 25px;
  margin: 0;
	font-weight: 500;
  color: $c_grey_dark;
}

.title a {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  padding: 0;
}

.title a:hover {
  color: #ccc;
  background: none;
  padding: 0;
}

.role {
  font-family: $f_role;
  text-align: right;
  font-size: 11px;
  color: $c_grey_dark;
}

.soon {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: $f_title;
	font-size: 12vw;
	color: $c_grey_light;
	opacity: .3;
	text-align: center;
	width: 100%;
	z-index: 1;
}
