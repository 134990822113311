@import 'vars.scss';

.home__link {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 2px solid $c_green;
	width: 200px;
	padding: 5px;
	text-align: center;
	z-index: 10;
	transition: .4s;
	font-family: $f_role;
	font-size: 20px;
	font-weight: bold;
	color: $c_green;

	&:hover {
		border-color: $c_grey_light;
		color: $c_grey_dark;
	}
}
