@import './vars';

.accordion {
	position: relative;
	margin-bottom: 50px;
	z-index: 2;
}
.accordion__row {
  border-bottom: 1px solid $c_grey_dark;
  padding: 2px 0;
	position: relative;


  &:last-child {
    border-bottom: none;
  }

  &.open {

    .projects {
      a {
        background-color: transparent;
        color: $c_grey_dark;
        padding: 5px 0px;

        &:hover {
          background-color: $c_green;
          color: $c_grey_light;
          padding: 5px 10px;
        }
      }
    }
  }
}

.accordion__tab {
  font-size: 16px;
  color: $c_grey_dark;
  bakcground-color: transparent;
  display: block;
  width: 100%;
  outline: none;
  border: none;
  text-align: left;
  padding: 5px 0px;
	box-sizing: border-box;
  transition: .4s;

	* {
		font-size: inherit;
	}

  .open &,
  &:hover {
    background-color: $c_green;
    color: $c_grey;
    padding: 5px 10px;

		.text {
			color: $c_grey;
		}
  }
}

.accordion__content {
  max-height: 1px;
  overflow: hidden;
  transition: max-height .4s linear, padding .4s ease-out;

  sup,
  sub {
    font-size: .8rem;
    padding-left: 5px;
  }

  .open & {
    max-height: 3000px;
    padding: 20px 0;
  }

	a {
	  text-decoration: none;
	  color: $c_green;
	  padding: 5px 0;
	  display: block;
	  transition: .4s;
	  margin: 0;
	  background-color: transparent;
	  cursor: pointer;
	}

	a:hover {
	  color: $c_grey;
	  background: $c_green;
	  padding: 5px 10px;
	}
}

.file {
  background: none;
  text-align: right;
  display: block;
  a {
    background: none;
    color: #709280;
    padding: 10px 0;
    display: inline-block;

    &:hover {
      color: #ccc;
      padding: 10px 0;
    }
  }
}

.cv {

  &__bio{
      p {
        color: $c_green;
        font-size: 14px;
        line-height: 20px;
      }

      a {
        color: $c_grey_dark;
        display: inline-block;
        font-size: inherit;
        line-height: inherit;
        padding: 0;

        &:hover {
          background: none;
          color: $c_green;
          padding: 0;
        }
      }
  }

  &__section {
    padding: 10px 0;
    max-width: 1200px;
    text-align: justify;
    line-height: 18px;
    // line-height: 18px;
    // padding: 0 0 0 10px!important;
    // margin: 0 0 10px;
  }

  &__title {
      padding: 0;
      margin: 0 0 10px;
      font-size: 14px;
      color: $c_green;
      font-weight: bold;
  }

  &__text {

    &--item {
      // display: flex;
      // align-items: flex-start;
			position: relative;
			padding-left: 25px;
      padding-bottom: 8px;
      sub {
        padding-left: 0!important;
        margin: 0 4px 0 0;
        font-size: 9px!important;
      }

      strong {
        margin-right: 8px;
        font-style: italic;
      }

			.tag {
				position: absolute;
				top: 0;
				left: 0;
				width: 16px;
				height: 100%;
				overflow: hidden;
				// display: flex;
				display: none;

				span {
					flex: 1;


					&.video {
						background: $c_grey_dark;
					}

					&.lighting {
						background: $c_yellow;
					}

					&.dev {
						background: $c_blue;
					}

					&.performing {
						background: $c_teal;
					}

					&.installation {
						background: $c_red;
					}
				}
			}
    }
  }
}

.gallery {
	padding: 20px 0;

	&__image {
		width: 500px;
		display: inline-block;
		margin: 10px 20px 10px 0;

		img {
			width: 100%;

		}
	}
}

.tags {
	position: absolute;
	top: 7px;
	right: 10px;
	display: none;

	.tag {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		border: 1px solid $c_grey;
		display: inline-block;
		margin-left: 10px;

		&.video {
			background: $c_grey_dark;
			// border-color: $c_grey_dark;
		}

		&.lighting {
			background: $c_yellow;
			// border-color: $c_yellow;
		}

		&.dev {
			background: $c_blue;
			// border-color: $c_blue;
		}

		&.performing {
			background: $c_teal;
			// border-color: $c_teal;
		}

		&.installation {
			background: $c_red;
			// border-color: $c_red;
		}

	}
}
